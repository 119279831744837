.content-redes {
    display: inline-block;
    width: 30%;

    @media all and (max-width: 678px) {
        text-align: center;
        width: 65%;
        margin: 0 auto;
    }

    p {
        text-transform: uppercase;
        vertical-align: middle !important;
        align-self: center;
        color: #811e6c;
        font-weight: 700 !important;
        font-size: 1.4em;
        margin: 0;
        display: inline-block;
    }

    .columns {
        display: inline-block;
        width: 49%;

        &.content-icons {
            a {
                margin-left: 10px;

                img {
                    width: 25px !important;
                    cursor: pointer;
                }
            }
        }
    }
}