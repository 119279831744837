.btn-bg-sc {
    width: 100% !important;
    border-radius: 4px;
    border: none;
    padding: 7px;
    color: #fff;
    display: block;
    text-decoration: none;
    cursor: pointer !important;
    text-transform: uppercase;

    &:hover {
        background-color: #caacc3 !important;
        color: #fff;
    }

    &.bg-purple {
        background-color: #811e6c;
    }

    &.bg-gray {
        background-color: #4f5152;
    }
}