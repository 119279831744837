.table {

    tbody,
    tfoot {
        border-top: none !important;
    }
    td, th {
        padding: 0.75rem !important;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }
    tr {
        font-weight: 700;
        text-align: center;

        &.head {
            color: #fff;
        }
    }

    &#table-purple {
        .head {
            background-color: #811e6c;
        }
    }

}